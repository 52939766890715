<template>
  <div class="d-flex flex-column align-baseline flex-nowrap">
    <span class="mr-2 nowrap name">{{name}}</span>
    <slot name="doc-type" />
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
  },
  computed: {
    value () {
      return this.cache?.doc?.id
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    cacheType () {
      return ClientCache.CacheType.CultureRef
    }
  },
  methods: {
  },
  data () {
    return {
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    mixed: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
